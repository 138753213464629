// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "@/utils/ZiqniAdminApiHandler.js";
import apiResponseFields from '@/generated/ziqni/fields/ApiResponseZq.js';
import walletResponseFields from '@/generated/ziqni/fields/WalletResponseZq.js';


const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenWalletsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenWalletsKey');
    },

    //WalletResponse
    [types.GETWALLETS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETWALLETS_ITEM_SUCCESS,walletResponseFields, state, response, 'wallet');
    },

    //WalletResponse
    [types.GETWALLETS_ERROR](state, error) {
      handleItemErrorResponse(types.GETWALLETS_ITEM_ITEM_ERROR, walletResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEWALLETS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEWALLETS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEWALLETS_ERROR](state, error) {
      handleErrorResponse(types.CREATEWALLETS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEWALLETS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEWALLETS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEWALLETS_ERROR](state, error) {
      handleErrorResponse(types.DELETEWALLETS_ERROR, apiResponseFields, state, error);
    },

    //WalletResponse
    [types.GETWALLETS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETWALLETS_SUCCESS,walletResponseFields, state, response);
    },

    //WalletResponse
    [types.GETWALLETS_ERROR](state, error) {
      handleErrorResponse(types.GETWALLETS_ERROR, walletResponseFields, state, error);
    },

    //WalletResponse
    [types.MANAGEWALLETTRANSACTION_SUCCESS](state, response) {
      handleSuccessResponse(types.MANAGEWALLETTRANSACTION_SUCCESS,walletResponseFields, state, response);
    },

    //WalletResponse
    [types.MANAGEWALLETTRANSACTION_ERROR](state, error) {
      handleErrorResponse(types.MANAGEWALLETTRANSACTION_ERROR, walletResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEWALLETS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEWALLETS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEWALLETS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEWALLETS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
