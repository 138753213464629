// Genearetd using ziqni-admin-api.mustache
import {ApiClient} from '@ziqni-tech/admin-api-client';
import { WalletTypesApi } from "@ziqni-tech/admin-api-client";

const defaultClient = ApiClient.instance;
defaultClient.basePath = sdkApiConfig.baseApiUrlPath;

import { sdkApiConfig } from '@/config';
import { queryConverter } from '@/utils/query.convert';
import { errorHandler } from '@/utils/errorHandler';
class WalletTypesApiZiqni {

  constructor() {
    this.defaultOptions = {};
    this.ziqniWalletTypesApi = new WalletTypesApi();
    this.OAuth2 = this.ziqniWalletTypesApi.apiClient.authentications['OAuth2'];
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
  }

  /**
   * createWalletTypes: Create wallet types. Notes: Create new Wallet types in the Ziqni database
   *
   * BaseName: WalletTypes
   * Path: /wallet-types
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  RequiredParams:
   *   - body [body]   >>> Create Wallet Types in the Ziqni database
   *      |  dataType: [CreateWalletTypeRequest], baseType: CreateWalletTypeRequest, 
   *      |  isBodyParam: true, 
   *      | 
   * 
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: false,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false  hasRequiredParams
   */
  createWalletTypes(body){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniWalletTypesApi.createWalletTypes(body, callback);
    });
  }

  /**
   * deleteWalletTypes: Delete wallet types. Notes: Delete Wallet types for a given identifier specified
   *
   * BaseName: WalletTypes
   * Path: /wallet-types
   * Http Method: DELETE, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - id [id]   >>> The unique identifiers of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  deleteWalletTypes(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniWalletTypesApi.deleteWalletTypes(opts, callback);
    });
  }

  /**
   * getWalletTypes: Get wallet types. Notes: Returns a list of Wallet Types. This assumes that wallet types have first been uploaded via a POST request or web console
   *
   * BaseName: WalletTypes
   * Path: /wallet-types
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: WalletTypeResponse, ReturnBaseType: WalletTypeResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - id [id]   >>> The unique identifiers of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getWalletTypes(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniWalletTypesApi.getWalletTypes(opts, callback);
    });
  }

  /**
   * updateWalletTypes: Update wallet types. Notes: Update existing Wallet types in the Ziqni database
   *
   * BaseName: WalletTypes
   * Path: /wallet-types
   * Http Method: PUT, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  RequiredParams:
   *   - body [body]   >>> Update Wallet types in the Ziqni database.
   *      |  dataType: [UpdateWalletTypeRequest], baseType: UpdateWalletTypeRequest, 
   *      |  isBodyParam: true, 
   *      | 
   * 
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: false,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false  hasRequiredParams
   */
  updateWalletTypes(body){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniWalletTypesApi.updateWalletTypes(body, callback);
    });
  }

}

export const walletTypesApiZiqni = new WalletTypesApiZiqni();
export default WalletTypesApiZiqni;
