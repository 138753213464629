// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import modelDefault from './ModelDefaultZq';
import optParamModels from './OptParamModelsZq';
import walletAllOf from './WalletAllOfZq';

const wallet = this;

export default {
  objectType: 'Wallet',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'wallets',
  hiddenFields: ['spaceName','customFields','metadata',],
  searchableFields: ['id','created','walletTypeId','memberId',], 
  stringBuilderFields: ['walletTypeId','memberId',],
  operations: ['Create','Update'], 
  searchableAllFields: ['walletTypeId','memberId',],
  translatableFields: [],
  sortableFields: ['id','spaceName','created','tags',],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    list: 'ListWallet',
    preview: 'PreviewWallet',
    create: 'CreateWallet',
    update: 'UpdateWallet',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "name",
      "order": "Asc"
    }
  ],
  fieldList: ['id','spaceName','created','customFields','tags','metadata','walletTypeId','memberId','balance'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 5000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 1,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 10000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    walletTypeId: {
      name: 'walletTypeId',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    memberId: {
      name: 'memberId',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    balance: {
      name: 'balance',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isNumber',
                     ],
      formType: null,
    }
  }
};
