// const Wallets = () => import('@/generated/ziqni/views/wallets/WalletsTable.vue');
const Wallets = () => import('@/views/ziqni/wallets/WalletsTable.vue');
const PreviewWallet = () => import('@/views/ziqni/wallets/WalletDetails.vue');
const EditWallet = () => import('@/views/ziqni/wallets/UpdateWallet.vue');
const CreateWallet = () => import('@/generated/ziqni/views/wallets/CreateWallet.vue');

export default {
  path: 'wallets',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'Wallets',
      component: Wallets,
    },
    {
      path: 'create',
      name: 'CreateWallet',
      component: CreateWallet,
    },
    {
      path: 'detail/:id',
      name: 'PreviewWallet',
      component: PreviewWallet,
    },
    {
      path: 'edit-wallet/:id',
      name: 'EditWallet',
      component: EditWallet,
    },
  ],
}
