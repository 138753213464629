// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import instantWinAllOf from './InstantWinAllOfZq';
import instantWinTile from './InstantWinTileZq';
import modelDefault from './ModelDefaultZq';
import optParamModels from './OptParamModelsZq';

const instantWin = this;

export default {
  objectType: 'InstantWin',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: ['customFields','metadata','spaceName',],
  searchableFields: ['id','created', 'name', 'description','tags'],
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: ['tags','id','spaceName','created',],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {


  },
  fieldList: ['name','description','termsAndConditions','constraints','statusCode','instantWinType','playedCount','active','tiles','startDate','endDate','version','style','customFields','tags','metadata','id','spaceName','created'],
  fields: {
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    statusCode: {
      name: 'statusCode',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    instantWinType: {
      name: 'instantWinType',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isUnboundedInteger',
                     ],
      formType: null,
    },
    playedCount: {
      name: 'playedCount',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isUnboundedInteger',
                     ],
      formType: null,
    },
    active: {
      name: 'active',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isBoolean',
                     ],
      formType: null,
    },
    // icon: {
    //   name: 'icon',
    //   complexType: null,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isPrimitiveType','isString',
    //                  ],
    //   formType: null,
    // },
    // banner: {
    //   name: 'banner',
    //   complexType: null,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isPrimitiveType','isString',
    //                  ],
    //   formType: null,
    // },
    // bannerLowResolution: {
    //   name: 'bannerLowResolution',
    //   complexType: null,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isPrimitiveType','isString',
    //                  ],
    //   formType: null,
    // },
    // bannerHighResolution: {
    //   name: 'bannerHighResolution',
    //   complexType: null,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isPrimitiveType','isString',
    //                  ],
    //   formType: null,
    // },
    tiles: {
      name: 'tiles',
      complexType: instantWinTile,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    startDate: {
      name: 'startDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    endDate: {
      name: 'endDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    version: {
      name: 'version',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isLong',
                     ],
      formType: null,
    },
    style: {
      name: 'style',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 5000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 1,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 10000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    }
  }
};
