import Vue from 'vue';
const types = {
  SWITCH: 'switch',
  TEXT: 'text',
  NUMBER: 'number',
  NAMEKEY: 'namekey',
  TEXTAREA: 'textarea',
  TAG: 'tag',
  ID: 'id',
  CUSTOM: 'custom',
  META: 'meta',
  ACTIONTYPEADJUSTMENTFACTORS: 'actiontypeadjustmentfactors',
  SELECT: 'select',
  ENTITIES: 'entities',
  LANGUAGES: 'languages',
  TRANFORMER: 'transformer',
  MEMBERIDS: 'memberids',
  CREATED: 'created',
  CONSTRAINT: 'constraint',
  ICON: 'icon',
  DATETIME: 'datetime',
  AWARDSIDS: 'awardsids',
  TRANSFORMERIDS: 'transformerids',
  HOSTINGOPTIONS: 'hostingoptions',
  REWARDTYPEIDS: 'rewardtypeids',
  TRIGGERS: 'triggers',
  ENTITYREFIDS: 'entityrefids',
  REWARDENTITYTYPE: 'rewardentitytype',
  UNITOFMEASURE: 'unitofmeasure',
  TEXTARRAY: 'textarray',
  INSTANTWINTYPE: 'instantwintype',
  WALLETTYPEIDS: 'wallettypeids',
};
export default {
  ...types,
};
Vue.prototype.$formType = {
  ...types,
};
