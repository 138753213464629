// const Wallets = () => import('@/generated/ziqni/views/wallets/WalletsTable.vue');
const WalletTypes = () => import('@/views/ziqni/walletTypes/WalletTypesTable.vue');
const PreviewWalletType = () => import('@/generated/ziqni/views/walletTypes/WalletTypeDetails.vue');
const EditWalletType = () => import('@/generated/ziqni/views/walletTypes/UpdateWalletType.vue');
const CreateWalletType = () => import('@/generated/ziqni/views/walletTypes/CreateWalletType.vue');

export default {
  path: 'wallets',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'WalletTypes',
      component: WalletTypes,
    },
    {
      path: 'create',
      name: 'CreateWalletType',
      component: CreateWalletType,
    },
    {
      path: 'detail/:id',
      name: 'PreviewWalletType',
      component: PreviewWalletType,
    },
    {
      path: 'edit-wallet-type/:id',
      name: 'EditWalletType',
      component: EditWalletType,
    },
  ],
}
