// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETWALLETTYPES_ITEM_REQ = 'GETWALLETTYPES_ITEM_REQ';
export const GETWALLETTYPES_ITEM_ERROR = 'GETWALLETTYPES_ITEM_ERROR';
export const GETWALLETTYPES_ITEM_SUCCESS = 'GETWALLETTYPES_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEWALLETTYPES_REQ = 'CREATEWALLETTYPES_REQ';
export const CREATEWALLETTYPES_ERROR = 'CREATEWALLETTYPES_ERROR';
export const CREATEWALLETTYPES_SUCCESS = 'CREATEWALLETTYPES_SUCCESS';

export const DELETEWALLETTYPES_REQ = 'DELETEWALLETTYPES_REQ';
export const DELETEWALLETTYPES_ERROR = 'DELETEWALLETTYPES_ERROR';
export const DELETEWALLETTYPES_SUCCESS = 'DELETEWALLETTYPES_SUCCESS';

export const GETWALLETTYPES_REQ = 'GETWALLETTYPES_REQ';
export const GETWALLETTYPES_ERROR = 'GETWALLETTYPES_ERROR';
export const GETWALLETTYPES_SUCCESS = 'GETWALLETTYPES_SUCCESS';

export const UPDATEWALLETTYPES_REQ = 'UPDATEWALLETTYPES_REQ';
export const UPDATEWALLETTYPES_ERROR = 'UPDATEWALLETTYPES_ERROR';
export const UPDATEWALLETTYPES_SUCCESS = 'UPDATEWALLETTYPES_SUCCESS';

