export const config = {
    onLoad: 'login-required',
    url: "https://identity.ziqni.com",  //process.env.VUE_APP_KEYCLOAK_URL
    checkLoginIframe: false // this is done to enable loading of iframe for KC recaptcha - https://stackoverflow.com/questions/68509296/keycloak-js-initialization-failed-because-an-ancestor-violates-the-content-secur
};

export const prodConfig = {
    ...config,
    realm: "ziqni", //process.env.VUE_APP_KEYCLOAK_REALM
    clientId: window.location.hostname  //process.env.VUE_APP_KEYCLOAK_CLIENT_ID // this value will be the fqdn of the root url to support multi-tenancy.  https://<SPACE-NAME>.ziqni.app is the client id in KC <space-name>.ziqni.app
};

export const devConfig = {
    ...config,
    realm: "ziqni", //process.env.VUE_APP_KEYCLOAK_REALM
    clientId: window.location.hostname.replace("local-unsafe.ziqni.app", "ziqni.app")
};

export const stagingConfig = {
    ...config,
    realm: "ziqni-staging", //process.env.VUE_APP_KEYCLOAK_REALM
    clientId: window.location.hostname  //process.env.VUE_APP_KEYCLOAK_CLIENT_ID // this value will be the fqdn of the root url to support multi-tenancy.  https://<SPACE-NAME>.app.staging.ziqni.io is the client id in KC <space-name>.app.staging.ziqni.io
};

export const stagingDevConfig = {
    ...config,
    realm: "ziqni-staging",
    clientId: `${process.env.DEV_STAGING_CLIENT_ID}.app.staging.ziqni.io`
};
