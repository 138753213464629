// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import entityType from './EntityTypeZq';
import modelDefault from './ModelDefaultZq';
import optParamModels from './OptParamModelsZq';
import rewardAllOf from './RewardAllOfZq';
import rewardTypeReduced from './RewardTypeReducedZq';

const reward = this;

export default {
  objectType: 'Reward',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'rewards',
  hiddenFields: ['spaceName','customFields','metadata','description','icon',],
  searchableFields: ['id','name','description','rewardRank','rewardValue','rewardType','issueLimit','pointInTime', 'entityId', 'entityType', 'constraints','tags'], // 'delay' 'period'
  stringBuilderFields: ['name',],
  operations: ['Create','Update'],
  searchableAllFields: ['name','description',],
  translatableFields: ['name','description',],
  sortableFields: ['id','spaceName','created','tags','rewardRank',],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    list: 'ListReward',
    preview: 'PreviewReward',
    create: 'CreateReward',
    update: 'UpdateReward',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "name",
      "order": "Asc"
    }
  ],
  fieldList: ['id','spaceName','created','customFields','tags','metadata','entityType','entityId','name','description','rewardRank','rewardValue','rewardType','icon','issueLimit','delay','pointInTime','period','constraints'],
  fields: {
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 5000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 1,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 10000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    entityType: {
      name: 'entityType',
      complexType: entityType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: [
                     ],
      formType: null,
    },
    entityId: {
      name: 'entityId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rewardRank: {
      name: 'rewardRank',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rewardValue: {
      name: 'rewardValue',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isDouble',
                     ],
      formType: null,
    },
    rewardType: {
      name: 'rewardType',
      complexType: rewardTypeReduced,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 1000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'IMAGE',
    },
    issueLimit: {
      name: 'issueLimit',
      complexType: null,
      displayOrder: 1005,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    delay: {
      name: 'delay',
      complexType: null,
      displayOrder: 1005,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    pointInTime: {
      name: 'pointInTime',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    period: {
      name: 'period',
      complexType: null,
      displayOrder: 1007,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isConstraintsArray','required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    }
  }
};
