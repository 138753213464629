// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import entityType from './EntityTypeZq';
import modelDefault from './ModelDefaultZq';
import rewardTypeReduced from './RewardTypeReducedZq';

const award = this;

export default {
  objectType: 'Award',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'awards',
  hiddenFields: ['spaceName',],
  searchableFields: ['id','memberId','rewardId','rewardRank','rewardValue','delay','entityId','pointInTime','period','tags','metadata','constraints','claimedTimestamp',], // 'activeUntil' 'statusCode' 'activeFrom' 'created'
  stringBuilderFields: [],
  operations: [],
  searchableAllFields: ['memberId','rewardRank','rewardValue','entityId','pointInTime','period','tags','statusCode','activeFrom','activeUntil',],
  translatableFields: [],
  sortableFields: ['id','spaceName','created',],
  customFieldsFieldName: [],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    list: 'ListAward',
    preview: 'PreviewAward',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "name",
      "order": "Asc"
    }
  ],
  fieldList: ['id','spaceName','created','memberId','rewardId','rewardType','rewardRank','rewardValue','delay','entityType','entityId','pointInTime','period','tags','metadata','constraints','claimedTimestamp','memberRefId','statusCode','activeFrom','activeUntil'],
  fields: {
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    memberId: {
      name: 'memberId',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rewardId: {
      name: 'rewardId',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rewardType: {
      name: 'rewardType',
      complexType: rewardTypeReduced,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    rewardRank: {
      name: 'rewardRank',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rewardValue: {
      name: 'rewardValue',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isDouble',
                     ],
      formType: null,
    },
    delay: {
      name: 'delay',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    entityType: {
      name: 'entityType',
      complexType: entityType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    entityId: {
      name: 'entityId',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    pointInTime: {
      name: 'pointInTime',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    period: {
      name: 'period',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isConstraintsArray','required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    claimedTimestamp: {
      name: 'claimedTimestamp',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    memberRefId: {
      name: 'memberRefId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    statusCode: {
      name: 'statusCode',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    activeFrom: {
      name: 'activeFrom',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    activeUntil: {
      name: 'activeUntil',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    }
  }
};
