
import * as types from './mutation-types';

import updateFields from '@/utils/updateFields';
import { walletsApiZiqni } from '@/generated/ziqni/api/WalletsApiZiqni';


export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenWalletsKey');
};

// Clear wallets Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//WALLETS - WALLETS
export const handleWalletsChanges = ({ commit }, data) => {
  commit('SET_WALLETS', data);
};
/**
 * WalletResponse from backend.
 * GET getWallets and return [WalletResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetWallets_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = {
    id: idArray,
    limit: limit || 1,
    skip: skip || 0
  };

  return walletsApiZiqni
    .getWallets(
      opts
    )
    .then((response) => {
      commit(types.GETWALLETS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETWALLETS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createWallets and return [ApiResponse]
 * vars: [
 *    [[body]] CreateWalletRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateWallets = ({ commit }, payload) => {
  const { createWalletRequestArray } = payload;

  commit(types.API_REQUEST);


  return walletsApiZiqni
    .createWallets(
      createWalletRequestArray
    )
    .then((response) => {
      commit(types.CREATEWALLETS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEWALLETS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteWallets and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteWallets = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = {
    id: idArray
  };

  return walletsApiZiqni
    .deleteWallets(
      opts
    )
    .then((response) => {
      commit(types.DELETEWALLETS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEWALLETS_ERROR, e);
      return [];
    });
};

/**
 * WalletResponse from backend.
 * GET getWallets and return [WalletResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetWallets = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = {
    id: idArray,
    limit: limit,
    skip: skip
  };

  return walletsApiZiqni
    .getWallets(
      opts
    )
    .then((response) => {
      commit(types.GETWALLETS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETWALLETS_ERROR, e);
      return [];
    });
};

/**
 * WalletResponse from backend.
 * POST manageWalletTransaction and return [WalletResponse]
 * vars: [
 *    [[body]] WalletTransactionRequest[ opts, isBodyParam, ]]
 */
export const handleManageWalletTransaction = ({ commit }, payload) => {
  const { walletTransactionRequest } = payload;

  commit(types.API_REQUEST);
  let opts = {
    body: walletTransactionRequest
  };

  return walletsApiZiqni
    .manageWalletTransaction(
      opts
    )
    .then((response) => {
      commit(types.MANAGEWALLETTRANSACTION_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.MANAGEWALLETTRANSACTION_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateWallets and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateWalletRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateWallets = ({ commit }, payload) => {
  const { updateWalletRequestArray } = payload;

  commit(types.API_REQUEST);


  return walletsApiZiqni
    .updateWallets(
      updateWalletRequestArray
    )
    .then((response) => {
      commit(types.UPDATEWALLETS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEWALLETS_ERROR, e);
      return [];
    });
};
