// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETWALLETS_ITEM_REQ = 'GETWALLETS_ITEM_REQ';
export const GETWALLETS_ITEM_ERROR = 'GETWALLETS_ITEM_ERROR';
export const GETWALLETS_ITEM_SUCCESS = 'GETWALLETS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEWALLETS_REQ = 'CREATEWALLETS_REQ';
export const CREATEWALLETS_ERROR = 'CREATEWALLETS_ERROR';
export const CREATEWALLETS_SUCCESS = 'CREATEWALLETS_SUCCESS';

export const DELETEWALLETS_REQ = 'DELETEWALLETS_REQ';
export const DELETEWALLETS_ERROR = 'DELETEWALLETS_ERROR';
export const DELETEWALLETS_SUCCESS = 'DELETEWALLETS_SUCCESS';

export const GETWALLETS_REQ = 'GETWALLETS_REQ';
export const GETWALLETS_ERROR = 'GETWALLETS_ERROR';
export const GETWALLETS_SUCCESS = 'GETWALLETS_SUCCESS';

export const MANAGEWALLETTRANSACTION_REQ = 'MANAGEWALLETTRANSACTION_REQ';
export const MANAGEWALLETTRANSACTION_ERROR = 'MANAGEWALLETTRANSACTION_ERROR';
export const MANAGEWALLETTRANSACTION_SUCCESS = 'MANAGEWALLETTRANSACTION_SUCCESS';

export const UPDATEWALLETS_REQ = 'UPDATEWALLETS_REQ';
export const UPDATEWALLETS_ERROR = 'UPDATEWALLETS_ERROR';
export const UPDATEWALLETS_SUCCESS = 'UPDATEWALLETS_SUCCESS';

