
import * as types from './mutation-types';

import updateFields from '@/utils/updateFields';
import { walletTypesApiZiqni } from '@/generated/ziqni/api/WalletTypesApiZiqni';


export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenWalletTypesKey');
};

// Clear walletTypes Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//WALLETTYPES - WALLETTYPES
export const handleWalletTypesChanges = ({ commit }, data) => {
  commit('SET_WALLETTYPES', data);
};
/**
 * WalletTypeResponse from backend.
 * GET getWalletTypes and return [WalletTypeResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetWalletTypes_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = {
    id: idArray,
    limit: limit || 1,
    skip: skip || 0
  };

  return walletTypesApiZiqni
    .getWalletTypes(
      opts
    )
    .then((response) => {
      commit(types.GETWALLETTYPES_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETWALLETTYPES_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createWalletTypes and return [ApiResponse]
 * vars: [
 *    [[body]] CreateWalletTypeRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateWalletTypes = ({ commit }, payload) => {
  const { createWalletTypeRequestArray } = payload;

  commit(types.API_REQUEST);


  return walletTypesApiZiqni
    .createWalletTypes(
      createWalletTypeRequestArray
    )
    .then((response) => {
      commit(types.CREATEWALLETTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEWALLETTYPES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteWalletTypes and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteWalletTypes = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = {
    id: idArray
  };

  return walletTypesApiZiqni
    .deleteWalletTypes(
      opts
    )
    .then((response) => {
      commit(types.DELETEWALLETTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEWALLETTYPES_ERROR, e);
      return [];
    });
};

/**
 * WalletTypeResponse from backend.
 * GET getWalletTypes and return [WalletTypeResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetWalletTypes = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = {
    id: idArray,
    limit: limit,
    skip: skip
  };

  return walletTypesApiZiqni
    .getWalletTypes(
      opts
    )
    .then((response) => {
      commit(types.GETWALLETTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETWALLETTYPES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateWalletTypes and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateWalletTypeRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateWalletTypes = ({ commit }, payload) => {
  const { updateWalletTypeRequestArray } = payload;

  commit(types.API_REQUEST);


  return walletTypesApiZiqni
    .updateWalletTypes(
      updateWalletTypeRequestArray
    )
    .then((response) => {
      commit(types.UPDATEWALLETTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEWALLETTYPES_ERROR, e);
      return [];
    });
};
