// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import appliesTo from './AppliesToZq';
import customFieldAllOf from './CustomFieldAllOfZq';
import fieldType from './FieldTypeZq';
import modelDefault from './ModelDefaultZq';

const customField = this;

export default {
  objectType: 'CustomField',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'customFields',
  hiddenFields: ['spaceName',],
  searchableFields: ['id','name','key','description',],
  stringBuilderFields: ['key',],
  operations: ['Create','Update'],
  searchableAllFields: ['id','name','key','description',],
  translatableFields: [],
  sortableFields: ['id','spaceName','created','name','key',],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    list: 'ListCustomField',
    preview: 'PreviewCustomField',
    create: 'CreateCustomField',
    update: 'UpdateCustomField',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "name",
      "order": "Asc"
    }
  ],
  fieldList: ['id','spaceName','created','name','key','description','fieldType','appliesTo','constraints'],
  fields: {
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    key: {
      name: 'key',
      complexType: null,
      displayOrder: 1000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    fieldType: {
      name: 'fieldType',
      complexType: fieldType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    appliesTo: {
      name: 'appliesTo',
      complexType: appliesTo,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    }
  }
};
