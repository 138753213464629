import { isProd, isStaging, isStagingDev } from "@/helpers/keycloak";
import {devConfig, prodConfig, stagingConfig} from "@/config/keycloakConfig";

export default {
    /**
     * API key used in the SDK to query the CL back office
     * @type {string}
     */
    apiKey: '969e1a4b9a314f869c60a284380768cb',
    /**
     * limit the amount of records requested from the API, mainly used for paginating data in table layouts
     * @type {number}
     */
    limit: 10,
    /**
     * limit the amount of records requested from the API, mainly used for /action-types/{spaceName}
     * @type {number}
     */
    actionTypeslimit: 1000,
    /**
     * skip the amount of records requested from the API, mainly used for paginating data in table layouts
     * @type {number}
     */
    skip: 0,
    /**
     * FIXME: Use -> localStorage.getItem("zq-space-name"); AND localStorage.getItem("zq-account-id");
     * Account Space name used in the SDK to query the CL back office, dictates which account the system queries
     * @type {string}
     */
    // spaceName: localStorage.getItem("zq-space-name"),
    //
    // accountID: localStorage.getItem("zq-account-id"),
    /**
     * SDK specific configuration index that controls the production/staging modes
     * - Production: 0
     * - Staging: 1
     * @type {number}
     */
    basePathIndex: 1,

    // baseApiUrlPath: 'https://api.ziqni.com',
    // baseApiUrlPath: isProd ? 'https://api.ziqni.com' : 'https://api.ziqni.com',
    baseApiUrlPath: isProd
        ? 'https://api.ziqni.com'
        : isStaging || isStagingDev
            ? 'https://api.staging.ziqni.io'
            : 'https://api.ziqni.com'
}
