import Keycloak from "keycloak-js";
import { prodConfig, devConfig, stagingConfig, stagingDevConfig } from "@/config/keycloakConfig";

const hostname = window.location.hostname;
const regexpDomain = /\.(ziqni.app)/;

export const isProd = regexpDomain.test(hostname) && hostname.split('.').length === 3;
export const isStaging = hostname.split('.').includes('staging');
export const isStagingDev = hostname.includes('127.0.0.1');

const config = isProd
  ? prodConfig
  : isStagingDev
    ? stagingDevConfig
    : isStaging
      ? stagingConfig
      : devConfig;


export const keycloak = Keycloak(config);
